<template>
<ModuleMaster 
    :requireSearchField="false"
    :requireAddButton="true"
    :requireExtraButtons="getCanEdit"
    :requirePaginator="true"
    :labels="labels"
    :numItemsPaginator="totalRecords"
    :selectedPage="selectedPage"
    :itemsPerPage="1000"
    :fnCallbackChangePage="handleChangePage"
    :fnCallbackCrudAction="handleCrudAction"
    :requireBackLink="true"
    backLink="/shopping/purchaseOrders"
    
>
    <Datatable :headers="headers" :requireActions="true" :requireStatusCol="false" style="padding-top: 16px" :hover="true">
        <Datarow 
            v-for="row in renderRows" 
            v-bind:key="row.ID" 
            :dataRow="row" 
            :showCrudActions="true"
            :requireStatusCol="false"
            :statusPropName="'status'"
        >
            <CrudActions 
                :itemID="row.ID" 
                :requireE="false" 
                :requireM="false"
                :requireV="true"
                :callbackCrudAction="handleCrudAction"
            />   
        </Datarow>
    </Datatable>
</ModuleMaster>
<button hidden data-bs-toggle="modal" data-bs-target="#modalPartialPayment" ref="btnForm"></button>
<PartialPayment 
    ref="form" 
    :mode="modeEditForm" 
    :title="titleEditFormAction" 
    :purchaseOrderId="getPurchaseOrderId" 
    :fnCallBackPersistenceNotifier="handleItemSaved"
    :currentOpenedItem="getCurrentPay"
/>

</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue';
import ModuleMaster from "../../../components/c-module-master/module-master.vue";
import Datatable from "../../../components/c-datatable/Datatable.vue";
import Datarow from "../../../components/c-datatable/Datarow.vue";
import ApiService from "@/core/services/ApiService";
import { formatToDatatable } from "../../../common/formaterToDatatable";
import SwalMessageService from "@/core/services/SwalMessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { extractParamValue, getBooleanValue } from "../../../common/utils";
import CrudActions from "../../../components/c-module-master/crud-actions.vue";
import PartialPayment from "./partial-payment.vue"

export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , CrudActions
        , PartialPayment
    },
    props: {
        C0: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        //VARIABLES Y CONSTANTES
        const messageService = new SwalMessageService();
        let records = ref([]);
        let modeForm = ref("N");
        let currentPage = ref(1);
        const cantRecordsPage = 1000;
        let totalItems = ref(0);
        const btnForm = ref(null);
        const form = ref(null);
        const currentItem = ref(null);
        let textSearch = ref("");
        let purchaseOrderId = ref("");
        let canModify = ref(true);
        let movFolio = ref("");
        
        
        const controller = "SettlementPurchaseOrders";
        let titleEditForm = ref("Nuevo Abono");
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo Abono",
        });
        const arrayTitleActions = [
            { action: "N", title: "Nuevo Abono" },
            { action: "V", title: "Visualizar Abono" }
        ];
        
        let configTable = ref([
            { PropName: "id", HeadLabel: "Id.", Type: "text", VisibleInGrid: false },
            { PropName: "responsibleName", HeadLabel: "Responsable", Type: "text", VisibleInGrid: true },
            { PropName: "amount", HeadLabel: "Importe", Type: "moneda", VisibleInGrid: true },
            { PropName: "currencyName", HeadLabel: "Tipo de Moneda", Type: "text", VisibleInGrid: true },
            { PropName: "paymentDate", HeadLabel: "Fecha de Abono", Type: "date", VisibleInGrid: true }
        ]);

        //HOOKS
        onBeforeMount(async() => {
            if(props) {
                purchaseOrderId.value = extractParamValue(atob(props.C0), "purchaseOrderId");
                await getList();
            }
        });

        onMounted(async() => {
            setCurrentPageBreadcrumbs(`Abonos${movFolio.value.trim().length > 0 ? " " : ""}${movFolio.value}`, ["Módulos", "Compras", { name: "Órdenes de Compra", to: "shopping/adminPurchaseOrders"}]);
        });

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.value.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(records.value, configTable.value, "status");
        });

        const totalRecords = computed(() => {
            return totalItems.value;
        });

        const selectedPage = computed(() => {
            return currentPage.value;
        });

        const modeEditForm = computed(() => {
            return modeForm.value;
        });

        const titleEditFormAction = computed(() => {
            return titleEditForm.value;
        });

        const getCanEdit = computed(() => {
            return canModify.value;
        });

        const getPurchaseOrderId = computed(() => {
            return purchaseOrderId.value;
        });

        const getCurrentPay = computed(() => currentItem.value);

        //FUNCIONES
        const handleChangePage = async(page) => {
            await goToPage(page);
        }

        const handleCrudAction = async(action, itemId) => {
            modeForm.value = action;
            titleEditForm.value = arrayTitleActions.some(a => a.action == action) ? arrayTitleActions.find(a => a.action == action).title : "";
            switch(action) {
                case "N":
                    if(btnForm.value) {
                        form.value.initDefaults();
                        btnForm.value.click();
                    }
                    break;
                case "V":
                    modeForm.value = action;
                    ApiService.get(`${controller}/${itemId}`).then(res => {
                        currentItem.value = res.data;
                        if(btnForm.value) {
                            form.value.initDefaults();
                            btnForm.value.click();
                        }
                    }).catch(reject => {
                        getMessageError(reject, messageService);
                    });
                    break;
            }
        }

        const getPagedItems = async() => {
            let criteriaSearch = `Criteria.MovementId=${purchaseOrderId.value}&Criteria.Active=true${textSearch.value.trim() != "" ? `&Criteria.Name=${textSearch.value.trim()}` : ""}`;
            let url = `${controller}/Detail/GetPagedList/${purchaseOrderId.value}?currentPage=${currentPage.value}&PageSize=${cantRecordsPage}&${criteriaSearch}`;
            ApiService.query(url).then(res => {
                totalItems.value = res.data.totalRecords;
                records.value = res.data.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getList = async() => {
            let url = `${controller}/GetList/${purchaseOrderId.value}`;
            ApiService.get(url).then(res => {
                totalItems.value = res.data.length;
                records.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleItemSaved = (item) => {
            switch(modeForm.value) {
                case "N":
                    if(currentPage.value > 1) {
                        goToPage(1);
                    } else {
                        if(records.value.length == cantRecordsPage){
                            records.value = [item, ...records.value.slice(0, cantRecordsPage - 1)];
                        } else {
                            records.value = [item, ...records.value];
                        }
                        totalItems.value += 1;                 
                    }
                    break;
            }
        }

        const goToPage = async (page) => {
            currentPage.value = page;
            await getPagedItems();
        }

        return {
            //variables y constantes
            labels,
            btnForm,
            form,

            //varaibles computadas
            headers,
            renderRows,
            totalRecords,
            selectedPage,
            modeEditForm,
            getCanEdit,
            getPurchaseOrderId,
            titleEditFormAction,
            getCurrentPay,

            //funciones
            handleChangePage,
            handleCrudAction,
            handleItemSaved,
        }
        
    },
})
</script>

<style>
    .extrabtns {
        display: flex;
        padding-right: 10px;
    }

    .btn-padd-left {
        padding-left: 10px;
    }
</style>