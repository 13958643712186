const extractParamValue = (source, paramName, separatorParams = "|", separatorKeyValue = ":") => {
    let valueParam = "";
    let keyValuePairs = source.split(separatorParams);
    let paramToGet = keyValuePairs.find(kvp => kvp.split(separatorKeyValue)[0] == paramName);
    if(paramToGet) {
        let keyValue = paramToGet.split(separatorKeyValue);
        if(keyValue && keyValue.length == 2) {
            return keyValue[1].trim();
        }
    }
}

const getBooleanValue = (srcValue) => {
    if(srcValue) {
        let srcValuePropType = typeof srcValue;
        if(srcValuePropType == "string") {
            return srcValue.toUpperCase() == 'TRUE';
        } else if(srcValuePropType == "boolean") {
            return srcValue;
        }
    }
}

export { extractParamValue, getBooleanValue }